import type React from 'react';
import { cn } from '~/utils/cn';

interface Props extends React.ComponentProps<'svg'> {}

export function Coin(props: Props) {
  return (
    <svg viewBox="0 0 28 29" fill="none" {...props} className={cn('size-7', props.className)}>
      <path
        d="M12.9609 0.882813V0.9375C18.8295 0.9375 24.2817 4.24733 26.3565 9.90625C28.4996 15.7517 26.2199 22.4196 21.0547 25.8568C19.7935 26.696 18.2019 27.5292 16.6797 27.7344C16.9631 27.845 17.376 27.6882 17.6641 27.6179C18.5453 27.4028 19.4141 27.0947 20.2344 26.7073C23.8464 25.0012 26.474 21.7778 27.5393 17.9453C29.2713 11.7142 26.0591 4.80207 20.1797 2.10282C17.8032 1.01177 15.5175 0.882534 12.9609 0.882813Z"
        fill="#BF9101"
      />
      <path
        d="M25.1568 21.5546H25.1021C24.2172 23.144 22.8258 24.5634 21.3287 25.5827C18.9163 27.225 16.0089 28.1889 13.0709 28.0603C4.90578 27.7027 -1.25914 19.8671 0.339604 11.8749C0.678052 10.183 1.36017 8.55298 2.31565 7.1171C3.49206 5.34926 5.04246 3.86548 6.89118 2.81215C9.0364 1.58987 11.4283 0.988149 13.8912 0.992101C15.9446 0.995396 18.1086 1.62229 19.9068 2.59492C20.6988 3.02333 21.3895 3.58161 22.149 4.0546C21.8705 3.50615 20.9752 3.08798 20.4537 2.7886C18.6758 1.76795 16.7033 1.13726 14.6568 0.987248C7.12885 0.435426 0.336228 6.28158 0.0027023 13.8983C-0.343865 21.8129 6.58416 28.8476 14.6021 28.0717C17.8641 27.7561 20.8995 26.4711 23.2427 24.1249C23.8921 23.4747 24.9399 22.4776 25.1568 21.5546Z"
        fill="#D9A509"
      />
      <path
        d="M12.7977 1.01458C9.59819 1.43468 6.73535 2.41141 4.32112 4.66033C3.14157 5.75911 2.18215 7.09816 1.46386 8.53825C-2.33189 16.1483 2.09565 25.5981 10.3367 27.6584C11.9855 28.0705 13.6866 28.158 15.368 27.9369C21.7817 27.0936 26.8406 21.5649 27.1237 15.1007C27.4094 8.57653 22.7038 2.55721 16.2977 1.27574C15.1813 1.05242 13.9358 0.86514 12.7977 1.01458Z"
        fill="#E9C34F"
      />
      <path
        d="M12.7429 2.87383C10.2252 3.20441 7.90587 3.94392 5.93018 5.62402C4.86952 6.52605 4.01888 7.65694 3.34432 8.86624C0.716425 13.5774 1.8006 19.9169 5.93018 23.4288C8.4009 25.5299 11.7744 26.5171 14.9851 26.1319C16.7487 25.9203 18.4662 25.3224 19.9617 24.3596C25.5956 20.7327 27.0429 12.5066 22.8027 7.23633C21.3115 5.38282 19.2751 4.03852 17.0086 3.34145C15.6841 2.93412 14.1277 2.69201 12.7429 2.87383Z"
        fill="#D7A301"
      />
      <path
        d="M13.1272 3.28875C10.8584 3.58679 8.77204 4.21702 6.92508 5.63576C5.82526 6.48057 4.91232 7.56065 4.18166 8.7343C1.2617 13.4246 2.434 19.7554 6.69725 23.2418C9.17088 25.2647 12.4444 26.1175 15.5881 25.6718C17.1472 25.4508 18.6253 24.8526 19.9631 24.0356C21.031 23.3835 21.9688 22.5114 22.7407 21.5312C26.5824 16.6518 25.6916 9.1549 20.6194 5.4763C19.5464 4.69808 18.3404 4.08522 17.0647 3.71895C15.8332 3.36538 14.4094 3.12032 13.1272 3.28875Z"
        fill="#E9C34F"
      />
      <path
        d="M25.1562 7.5C25.6189 8.77083 26.3432 9.88345 26.6938 11.2188C27.3689 13.7895 27.2098 16.4808 26.3406 18.9844C26.0288 19.8822 25.4768 20.6742 25.1562 21.5547C25.814 20.9912 26.1817 19.7332 26.4505 18.9297C27.3686 16.1853 27.4417 13.1603 26.5415 10.3984C26.2539 9.51611 25.8797 8.11972 25.1562 7.5Z"
        fill="#D9A505"
      />
      <g filter="url(#filter0_d_833_13156)">
        <path
          d="M16.6333 9.16743C16.7402 9.09262 16.847 9.03656 16.9538 8.99916C17.0606 8.95645 17.1674 8.93512 17.2742 8.93512C17.5947 8.93512 17.8884 9.19144 18.1554 9.70413C18.4224 10.2168 18.556 10.799 18.556 11.4505C18.556 11.7282 18.4865 11.9445 18.3476 12.0994C18.2142 12.2543 18.0246 12.3317 17.7789 12.3317C17.5599 12.3317 17.2849 12.1581 16.9538 11.811C16.895 11.7469 16.855 11.7042 16.8336 11.6828C16.2675 11.0953 15.7468 10.6895 15.2715 10.4652C14.7962 10.2355 14.2594 10.1207 13.6613 10.1207C12.7053 10.1207 11.971 10.4331 11.4583 11.058C10.9509 11.6828 10.6972 12.58 10.6972 13.7496C10.6972 15.4372 11.0577 16.7484 11.7787 17.6829C12.5051 18.6122 13.5278 19.0769 14.8469 19.0769C15.2688 19.0769 15.6507 19.0181 15.9925 18.9006C16.3396 18.7778 16.6413 18.5962 16.8977 18.3559C16.9938 18.2651 17.1166 18.1289 17.2662 17.9473C17.5225 17.6375 17.7442 17.4827 17.9311 17.4827C18.1393 17.4827 18.3049 17.5761 18.4278 17.763C18.556 17.95 18.62 18.2037 18.62 18.5241C18.62 18.9887 18.5292 19.3893 18.3476 19.7257C18.1661 20.0622 17.9551 20.2304 17.7148 20.2304C17.6133 20.2251 17.5145 20.2144 17.4184 20.1984C17.3223 20.177 17.2234 20.1476 17.122 20.1102C16.5826 20.4253 16.0352 20.6603 15.4798 20.8152C14.9297 20.9701 14.3689 21.0475 13.7975 21.0475C13.0979 21.0475 12.4223 20.9514 11.7707 20.7591C11.1245 20.5722 10.5584 20.3079 10.0724 19.9661C9.26602 19.3946 8.64116 18.6443 8.19787 17.715C7.75998 16.7804 7.54102 15.747 7.54102 14.6148C7.54102 13.9098 7.64246 13.2422 7.84541 12.6121C8.04835 11.9819 8.34744 11.4078 8.74261 10.8898C9.29271 10.1581 10.0163 9.58666 10.9136 9.17541C11.8108 8.76422 12.7881 8.55859 13.8456 8.55859C14.3155 8.55859 14.7802 8.60929 15.2394 8.71079C15.7041 8.81223 16.1687 8.96448 16.6333 9.16743Z"
          fill="#D7A301"
        />
      </g>
    </svg>
  );
}
